<div class="error-page">
  <div class="header">
    <p class="title-glorious">GLORIOUS</p>
  </div>
  <div class="container-error">
    <p class="title-error">404</p>
    <p class="text-error">Цієї сторінки не існує</p>
    <button class="button-error" routerLink="/">НА ГОЛОВНУ</button>
  </div>
</div>
