<div [ngClass]="{'open-blur': isContactOpen}">
<div class="header">
  <div class="container-header">
      <p class="title-glorious" routerLink="">GLORIOUS</p>
        @if(isAdmin){
          <button (click)="logout()" class="button-contact">
            LOGOUT
          </button>
        } @else {
          <button (click)="openContact()" class="button-contact">
            КОНТАКТ З НАМИ
          </button>
        }
  </div>
</div>
@if(!EDIT_VIEW) {
  <div class="container-product">
    <div class="block-product">
      <div class="block-product-text-mobile">
        <p class="title-main-mobile"><span routerLink="/catalog" class="title-main-span-mobile">Каталог/</span>{{product?.category?.label}}</p>
        <p></p>
        <p *ngIf="isAdmin" (click)="toggleEditView()" class="title-change-mobile"><img class="image-change-mobile" src="../../../assets/change.svg" alt=""><p class="text-change-mobile">Редагувати товар</p></p>
      </div>
      <div class="swiper-product">
        <div class="container-swiper">
          <div class="swiper-product-image" id="image-viewer" style="width: 100%; height: 100%;">
            <button class="container-arrow" style="left: 16px;" (click)="prevImage()" [disabled]="currentIndex === 0">
              <img class="swiper-button-prev" src="../../../assets/arrow-left.svg" alt="Prev">
            </button>
            <button class="container-arrow" style="right: 16px;" (click)="nextImage()" [disabled]="currentIndex === (this.product?.images?.length||0) - 1">
              <img class="swiper-button-next" src="../../../assets/arrow-right.svg" alt="Next">
            </button>
          </div>
          <!-- <img  [src]="this.product?.images?.[currentIndex]?.url" alt="Product Image" width="847" height="465" draggable="false"> -->
          
        </div>
        <div class="swiper-pagination">
          @for(image of this.product?.images; track $index){
            <div class="container-swiper-pagination-photo" (click)="selectImage($index)">
              <img class="swiper-pagination-photo" [src]="image?.url" alt="Thumbnail" draggable="false">
            </div>
          }
        </div>
      </div>
      <div class="block-product-text">
        <div class="block-title-product">
          <p class="title-main"><span class="title-main-span">Каталог/</span>{{product?.category?.label}}</p>
          <p *ngIf="isAdmin" (click)="EDIT_VIEW = true" class="title-change"><img class="image-change" src="../../../assets/change.svg" alt=""><p class="text-change">Редагувати товар</p></p>
        </div>
        <p class="title-product">{{product?.name || 'Назва товару'}} <span class="title-product-span-price"> {{product?.price}}</span></p>
        <p class="title-product-text">Опис</p>
        <div class="line"></div>
        <p class="text-product" [innerText]="product?.desc || ''">
        </p>
        <p class="title-product-text-garanty">Офіційна гарантія на виріб - 18 місяців.</p>
        <!-- Якщо користувач не адмін, показуємо кнопку ЗВ'ЯЗОК -->
        <a *ngIf="!isAdmin" href="tel:+380 73 800 4410"  class="button-product">ЗВ’ЯЗОК</a>
        <!-- Якщо користувач адмін, показуємо дві кнопки -->
        <div *ngIf="isAdmin" class="buttons-product">
          <button class="button-products" (click)="addToPopular()">
            {{product?.popular ? 'ВИДАЛИТИ З ПОПУЛЯРНИХ' : 'ДОДАТИ В ПОПУЛЯРНІ'}}
          </button>
          <button class="button-products" (click)="deleteProduct()">ВИДАЛИТИ ТОВАР</button>
        </div>
      </div>
    </div>
    <div class="block-info-product">
      <div class="block-info-right">
        <p class="title-info-product">Про товар</p>
        <div class="line-info"></div>
        <div class="lists-info-product">
          <ul class="list-info">
            <span class="item-info-title">Розмір дивана:</span>
            <div>
              <p class="textarea-info" [innerText]="product?.sizeInfo || ''"></p>
            </div>
          </ul>
          <ul class="list-info">
            <span class="item-info-title">Матеріали:</span>
            <p class="textarea-info-info" [innerText]="product?.materialInfo || ''"></p>
          </ul>
        </div>
      </div>
      <div class="block-info-left">
        <p class="title-info-product">Умови доставки та оплати</p>
        <div class="line-info-left"></div>
        <ul class="list-info-left">
          <div class="block-admin-product-info-text">
            <p class="textarea-info-info-left-text">Для купівлі товару необхідно зателефонувати за вказаним контактом.</p>
            <p class="textarea-info-info-left-text">Передоплата або оплата при отриманні.</p>
            <p class="textarea-info-info-left-text">Доставка здійснюється кур'єром (авто виробника), попутним транспортом або Новою Поштою в будь-яку точку України, окрім окупованих і де відбуваються військові дії.</p>
            <p class="textarea-info-info-left-text">Термін доставки від 3 днів, в залежності від замовлення і відстані.</p>
          </div>
          <span class="item-info-left-garanty" routerLink="/terms-of-use"><span class="item-info-left-garanty-span">УМОВИ ВИКОРИСТАННЯ МЕБЛІВ <img class="item-info-left-garanty-arrow" src="../../../assets/arrow-up-right.svg"></span></span>
        </ul>
      </div>
    </div>
    <!-- <p class="title-popular">РЕКОМЕНДУЄМО</p>
    <ul class="list-popular">
      <li class="item-popular">
        <img class="image-popular-arrow" src="../assets/arrow-up-right.svg" alt="" draggable="false">
        <img class="image-popular" src="../assets/sofa-list.png" alt="" draggable="false">
        <span class="item-popular-span">
          <p class="text-popular">ДЖЕК 160</p>
          <p class="text-popular-price">{{product?.price}} грн</p>
        </span>
      </li>
      <li class="item-popular">
        <img class="image-popular-arrow" src="../assets/arrow-up-right.svg" alt="" draggable="false">
        <img class="image-popular" src="../assets/sofa-list.png" alt="" draggable="false">
        <span class="item-popular-span">
          <p class="text-popular">ДЖЕК 160</p>
          <p class="text-popular-price">10 000 грн</p>
        </span>
      </li>
      <li class="item-popular">
        <img class="image-popular-arrow" src="../assets/arrow-up-right.svg" alt="" draggable="false">
        <img class="image-popular" src="../assets/sofa-list.png" alt="" draggable="false">
        <span class="item-popular-span">
          <p class="text-popular">ДЖЕК 160</p>
          <p class="text-popular-price">10 000 грн</p>
        </span>
      </li>
    </ul> -->
  </div>
} @else {
<div class="admin-panel">
    <div class="container-admin-panel">
        <div class="block-admin-products">
            <div class="photos-admin-product">
                <div class="photos-admin-product">
                  <div class="title-admin-product-mobile">
                    <div class="title-admin-product-text-mobile">
                      <div>
                        <span class="title-admin-product-span-mobile">Каталог/</span>
                        <select class="select-admin-product">
                          @for(category of categories; track $index){
                            <option value="{{category._id}}">{{category.label}}</option>
                          }
                        </select>
                      </div>
                      @if(product?._id !== 'new'){
                        <span (click)="toggleEditView()" class="title-admin-product-span-reg">Вийти з режиму редагування</span>
                      }
                    </div>
                  </div>
                    @if(mainPhoto?._id){
                      <div class="photo-admin-product">
                        <img 
                          [src]="mainPhoto?.url"
                          class="main-photo"
                          alt="Main Photo"
                        >

                        <button class="delete-photo-btn" (click)="deletePhoto(mainPhoto?._id)"> <img style="width:16px; height:16px; fill:white" src="../../../assets/trash.svg" alt=""></button>
                      </div>
                    }@else{
                      <div class="photo-admin-product">
                        @if(loadingImage){
                          <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                          </div>
                        }@else {
                          <img 
                          [src]="'../../../assets/plus.svg'"
                          class="plus-photo"
                          alt="Main Photo"
                        >
                        }
                        <input type="file" id="file-input-main" (change)="onFileSelected($event, true)" accept="image/*" style="opacity: 0; position: absolute; height: 100%; cursor: pointer;">
                      </div>
                    }
                    
                    <div class="small-photos-admin-product">
                      @for(photo of smallPhotos; track $index){
                        <div class="small-photo-admin-product">
                          <img 
                            [src]="photo.url"
                            class="small-photo"
                            alt="Small Photo"
                          >
                          <button class="delete-photo-btn" (click)="deletePhoto(photo?._id)"> <img style="width:16px; height:16px; fill:white" src="../../../assets/trash.svg" alt=""></button>
                        </div>
                      }
                      <div  class="small-photo-admin-product" (click)="onPhotoClick('small')">
                        @if(loadingImage){
                          <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                          </div>
                        }@else {
                          <img 
                            [src]=" '../../../assets/plus.svg'"
                            class="plus-photo-small"
                            alt="Small Photo"
                          >
                        }
                        <input type="file" [id]="'file-input-small'" (change)="onFileSelected($event, false)" accept="image/*" style="opacity: 0; position: absolute; height: 100%; cursor: pointer;">
                      </div>
                     
                    </div>
                    
                  </div>
                   
            </div>    
                      
            <div class="text-admin-product">
                <div class="title-admin-product">
                  <div>
                    <span class="title-admin-product-span">Каталог/</span>
                    <select (change)="setCategory($event)" class="select-admin-product">
                      <option value="">Вибрати</option>
                      @for(category of categories; track $index){
                        <option [value]="category._id" [selected]="product?.category?._id === category._id">{{category.label}}</option>
                      }
                    </select>
                  </div>
                  @if(product?._id !== 'new'){
                    <span (click)="toggleEditView()" class="title-admin-product-span-reg">Вийти з режиму редагування</span>
                  }
                </div>
                <input class="input-admin-product-name" [value]="product?.name || ''" (change)="setValue($event, 'name')" type="text" placeholder="Додати назву...">
                <input class="input-admin-product-price" type="text" [value]="product?.price || ''" (change)="setValue($event, 'price')" placeholder="Додати ціну...">
                
                <p class="text-admin-product-description">Опис</p>
                <div class="line-admin-product"></div>
                <textarea class="input-admin-product-description" autogrow [value]="product?.desc || ''" (change)="setValue($event, 'desc')"  type="text" rows="8" placeholder="Додати опис..."></textarea>
                <p class="text-admin-product-garanty">Офіційна гарантія на виріб - 18 місяців.</p>
                <button class="button-admin-product" (click)="toggleEditView()">ЗБЕРЕГТИ</button>
            </div>
        </div>
        <div class="block-admin-product">
            <div class="text-admin-product">
                <p class="title-admin-product-text">Про товар</p>
                <div class="line-admin-product-info"></div>
                <div class="block-admin-product-info">
                    <textarea class="input-admin-product-info" rows="10" autogrow type="text" [value]="product?.sizeInfo || ''" (change)="setValue($event, 'sizeInfo')" placeholder="Додати про розмір..."></textarea>
                    <textarea  class="input-admin-product-info-materials" autogrow [value]="product?.materialInfo || ''" (change)="setValue($event, 'materialInfo')"  type="text"  placeholder="Додати про матеріали..."></textarea>
                </div>
            </div>
            <div class="text-admin-product">
                <p class="title-admin-product-text">Умови доставки та оплати</p>
                <div class="line-admin-product-info"></div>
                <div class="block-admin-product-info-text">
                  <p class="textarea-info-info-left-text">Для купівлі товару необхідно зателефонувати за вказаним контактом.</p>
                  <p class="textarea-info-info-left-text">Передоплата або оплата при отриманні.</p>
                  <p class="textarea-info-info-left-text">Доставка здійснюється кур'єром (авто виробника), попутним транспортом або Новою Поштою в будь-яку точку України, окрім окупованих і де відбуваються військові дії.</p>
                  <p class="textarea-info-info-left-text">Термін доставки від 3 днів, в залежності від замовлення і відстані.</p>
                </div>
                </div>
        </div>
    </div>
</div>
}
</div>
<!-- contact for me -->
<div class="contact-me" [ngClass]="{'open': isContactOpen}">
  <div class="container-contact-me">
    @if(successForm){
      <div style="display: flex; flex-direction: column; ">
        <div style="display: flex; align-items: center; justify-content: space-between;">
          <p class="title-contact-me-success">ДЯКУЄМО!</p>
          <div style="display: flex; align-items: center; height: 100%; ">
            <img class="close-contact-me image-contact-me" (click)="closeContact()" [ngClass]="{'open': isContactOpen}" src="../../../assets/x.svg" alt="">
          </div>
        </div>
        <div>
          <span class="title-contact-me-success-bottom">ОЧІКУЙТЕ ДЗВІНКА НАЙБЛИЖЧИМ ЧАСОМ </span>
        </div>
      </div>
    }@else {
      <div class="block-contact-me">
        <p class="title-contact-me">ЗАЛИШ ЗАЯВКУ</p>
        <img class="close-contact-me image-contact-me" (click)="closeContact()" [ngClass]="{'open': isContactOpen}" src="../../../assets/x.svg" alt="">
      </div>
      <div class="block-input-contact-me">
        <input class="input-contact" type="text" [(ngModel)]="contactName" placeholder="Ім’я">
        <input class="input-contact" type="text" [(ngModel)]="contactPhone" placeholder="Номер телефону">
      </div>
      <button class="button-contact-me" (click)="sendContact()">ВІДПРАВИТИ</button>
    }
    
  </div>
 </div>