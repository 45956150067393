<div>
  <div class="header">
    <div class="container-header">
        <p class="title-glorious" routerLink="">GLORIOUS</p>
        <div class="menu-buttons">
            <button class="button-contact" routerLink="/catalog">КАТАЛОГ</button>
        </div>
    </div>
</div>
<div class="container-terms-of-use">
    <p class="title-terms-of-use">умови використання меблів</p>
    <p class="text-terms-of-use">М’які меблі від Glorious створені з високоякісних матеріалів і розраховані на довготривале використання. Щоб ваші меблі зберегли первинний вигляд і функціональність на багато років, будь ласка, дотримуйтеся наступних рекомендацій:    </p>
    <p class="title-terms-of-use-list">1. Загальні правила експлуатації</p>
    <ul class="list-terms-of-use">
        <li class="text-list">• Використовуйте меблі лише за прямим призначенням.</li>
        <li class="text-list">• Уникайте надмірного навантаження, яке може призвести до деформації або поломки меблів.</li>
        <li class="text-list">• Не сідайте на підлокітники або спинки, а також не стійте на сидіннях.</li>
        <li class="text-list">• Не тягніть і не штовхайте меблі при переміщенні, щоб уникнути пошкодження каркасу або ніжок. Рекомендується піднімати меблі під час переміщення.</li>
    </ul>
    <p class="title-terms-of-use-list">2. Температурні умови та освітлення</p>
    <ul class="list-terms-of-use">
        <li class="text-list">• Уникайте тривалого впливу прямих сонячних променів, оскільки це може спричинити вицвітання оббивки.</li>
        <li class="text-list">• Не розміщуйте меблі поруч із джерелами тепла (радіатори, обігрівачі), щоб уникнути пересушування матеріалів та їх деформації.</li>
        <li class="text-list">• Оптимальна температура для використання меблів: від +10°C до +30°C.</li>
    </ul>
    <p class="title-terms-of-use-list">3. Догляд за оббивкою</p>
    <ul>
        <li class="text-list">• Регулярно очищуйте поверхню від пилу за допомогою пилососа або м’якої щітки.</li>
        <li class="text-list">• Для видалення плям використовуйте м’які миючі засоби, що не містять агресивних хімічних речовин. Завжди випробовуйте засіб на непомітній ділянці меблів.</li>
        <li class="text-list">• Не допускайте надмірного зволоження оббивки. Якщо меблі намокли, обережно просушіть їх на відкритому повітрі, але не під прямими сонячними променями.</li>
        <li class="text-list">• Використовуйте спеціальні чохли або накидки для захисту оббивки від механічних пошкоджень і забруднень.</li>
    </ul>
    <p class="title-terms-of-use-list">4. Умови зберігання</p>
    <ul class="list-terms-of-use">
        <li class="text-list">• Якщо меблі не використовуються протягом тривалого часу, зберігайте їх у сухому, захищеному від вологи і пилу місці.</li>
        <li class="text-list">• Не ставте важкі предмети на поверхню м’яких меблів під час зберігання, щоб уникнути деформації.</li>
    </ul>
    <p class="title-terms-of-use-list">5. Гарантія</p>
    <ul class="list-terms-of-use">
        <li class="text-list">• Термін гарантії на меблі становить 2 роки з моменту покупки.</li>
        <li class="text-list">• Гарантія не покриває пошкодження, спричинені неналежним використанням, механічними ударами, надмірним навантаженням або недотриманням правил догляду.</li>
    </ul>
    <p class="title-terms-of-use-list">6. Контакти для підтримки</p>
    <p class="text-terms-of-use">Якщо у вас виникли запитання щодо експлуатації меблів або потрібна консультація з догляду, ви завжди можете звернутися до нашої служби підтримки клієнтів за телефоном або електронною поштою.</p>
</div>
</div>