<router-outlet></router-outlet>
<footer class="footer"  [ngClass]="{ 'main-footer': isMainPage }">
    <div class="container-footer-top">
      <p class="title-footer">GLORIOUS</p>
      <div class="block-footer">
        <div class="list-footer">
          <p class="title-text-footer" >ПРО НАС</p>
          <p class="text-footer text-scroll" (click)="scrollToSection('popular')">ПОПУЛЯРНІ ТОВАРИ</p>
          <p class="text-footer text-scroll" (click)="scrollToSection('advantages')">НАШІ ПЕРЕВАГИ</p>
          <p class="text-footer text-scroll" (click)="scrollToSection('history')">НАША ІСТОРІЯ</p>
        </div>
        <div class="list-footer">
          <p class="title-text-footer">ЗВ’ЯЗОК</p>
          <a href="tel:+380 73 800 4410" class="text-footer">+380 73 800 4410</a>
          <a href="tel:+380 68 800 4410" class="text-footer">+380 68 800 4410</a>
          <a href="mailto:gloriousmebel&#64;gmail.com" class="text-footer">gloriousmebel&#64;gmail.com</a>
        </div>
        <div class="list-footer">
          <p class="title-text-footer">СЛІДКУЙ ЗА НАМИ</p>
          <a href="https://www.facebook.com/profile.php?id=61563935930922" class="text-footer" target="_blank">FACEBOOK</a>
          <a href="https://www.instagram.com/glorious_sofa/" class="text-footer" target="_blank">INSTAGRAM</a>
        </div>
      </div>
    </div>
    <div class="container-footer-bottom">
      <div class="block-footer-bottom">
        <p class="title-text-footer">НАША АДРЕСА</p>
        <a href="https://maps.app.goo.gl/V6pgHoX6BtCKdhSL6" class="text-footer text-footer-map" target="_blank">Черкаси Вул. Чорновола Вʼячеслава Будинок 175</a>
      </div>
      <div>
        <p class="text-footer text-footer-bottom-mobile"><span routerLink="/terms-of-use" class="terms-of-use">УМОВИ ВИКОРИСТАННЯ МЕБЛІВ</span> Всі права захищені All rights reserved</p>
      </div>
    </div>
  </footer>